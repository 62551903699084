import React from 'react';
import logo from '../assets/logo.png'; // Adjust the path to your logo image
import '../css/Navbar.css';
import logo1 from '../assets/perl.png';
import nova from '../assets/nova.jpg';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={nova} alt="Company Logo" className="logo logo-left" />
        <img src={logo} alt="Company Logo" className="invoice-logo logo-center" />
        <img src={logo1} alt="Company Logo" className="logo logo-right" />
      </div>
    </nav>
  );
};

export default Navbar;
