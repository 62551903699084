import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import '../css/InvoiceForm.css'; // Import the CSS file
import logo from '../assets/logo.png';
import html2canvas from 'html2canvas';
import Navbar from './Navbar';
import logo1 from '../assets/perl.png';
import nova from '../assets/nova.jpg';


// Function to map URL path to branch name
const getBranchNameFromPath = (path) => {
  const branchMap = {
    '/market': 'MARKET',
    '/hongkong-place': 'HONGKONG PLACE',
    '/eral': 'ERAL',
    '/nova-main-branch': 'NOVA MAIN BRANCH',
    '/nova-thirespuram': 'NOVA-THIRESPURAM'
  };
  return branchMap[path] || 'UNKNOWN BRANCH';
};

const InvoicePreview = ({ formData, additionalFields, onPrint, branchName }) => {
  // Function to format the date as DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript
  
    return date.toLocaleDateString('en-GB'); // en-GB formats the date as DD-MM-YYYY
  };
  

  // Function to render additional fields in the invoice
  const renderAdditionalFields = () => {
    return additionalFields.map((field, index) => (
      <tr key={index}>
        <td>{field.label}</td>
        <td>{field.value}</td>
      </tr>
    ));
  };



  return (

<div id="invoice-preview" className="invoice-preview">
      <header className="invoice-header">
        <img src={nova} alt="Company Logo" className="logo-left" />
        <img src={logo} alt="Company Logo" className="invoice-logo" />
        <img src={logo1} alt="Company Logo" className="logo-right" />
      </header>
      <h2 className="ttt">Branch: {branchName.toUpperCase()}</h2>
      <h2 className="invoice-title">SALES REPORT</h2>
      <table className="invoice-table">
        <tbody>
          <tr>
            <td>Date:</td>
            <td>{formatDate(formData.date)}</td>
          </tr>
          <tr>
            <td>Customer Name:</td>
            <td>{formData.customerName}</td>
          </tr>
          <tr>
            <td>Branch:</td>
            <td>{formData.branch}</td>
          </tr>
          <tr>
            <td>Capacity:</td>
            <td>{formData.capacity}</td>
          </tr>
          <tr>
            <td>Make:</td>
            <td>{formData.make}</td>
          </tr>
          <tr>
            <td>Sales Invoice No.:</td>
            <td>{formData.salesInvoiceNo}</td>
          </tr>
          <tr>
            <td>Sales Price:</td>
            <td>{formData.salesPrice}</td>
          </tr>
          <tr>
            <td>Stamping:</td>
            <td>{formData.stamping}</td>
          </tr>
          <tr>
            <td>Stamping Number:</td>
            <td>{formData.stampingnum}</td>
          </tr>
          <tr>
            <td>Application Number:</td>
            <td>{formData.appnum}</td>
          </tr>
          {renderAdditionalFields()}
        </tbody>
      </table>
      <button onClick={onPrint} className="invoice-button">
        Print Invoice
      </button>
    </div>
  );
};


const InvoiceForm = ({ branchName }) => {
  const [formData, setFormData] = useState({
    date: '',
    customerName: '',
    branch: '', // This branch is different from branchName from URL
    capacity: '',
    make: '',
    salesInvoiceNo: '',
    salesPrice: '',
    stamping: ''
  });

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (index !== undefined) {
      const fields = [...additionalFields];
      fields[index] = { ...fields[index], value };
      setAdditionalFields(fields);
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleAddField = () => {
    setAdditionalFields([...additionalFields, { label: '', value: '' }]);
  };

  const handleLabelChange = (e, index) => {
    const { value } = e.target;
    const fields = [...additionalFields];
    fields[index] = { ...fields[index], label: value };
    setAdditionalFields(fields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handlePrint = () => {
    const invoiceElement = document.getElementById('invoice-preview');
  
    // Create a temporary container to hide the print button
    const printContainer = document.createElement('div');
    printContainer.style.position = 'absolute';
    printContainer.style.left = '-9999px';
    document.body.appendChild(printContainer);
    printContainer.innerHTML = invoiceElement.outerHTML;
  
    // Remove the print button from the temporary container
    const printButton = printContainer.querySelector('.invoice-button');
    if (printButton) printButton.style.display = 'none';
  
    html2canvas(printContainer, {
      scale: 2 // Increase scale for better image quality
    }).then(canvas => {
      document.body.removeChild(printContainer);
  
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'invoice.png';
      link.click();
    });
  };

  return (
    <div className="invoice-container">
        <Navbar />
      {showPreview ? (
        <InvoicePreview formData={formData} additionalFields={additionalFields} onPrint={handlePrint} branchName={branchName} />
      ) : (
        <form className="invoice-form" onSubmit={handleSubmit}>
          <h2 className="invoice-title">Invoice Generator</h2>

          <label className="invoice-label">
            Date:
            <br />
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Customer Name:
            <br />
            <input
              type="text"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Branch:
            <br />
            <input
              type="text"
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Capacity:
            <br />
            <input
              type="text"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Make:
            <br />
            <input
              type="text"
              name="make"
              value={formData.make}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Sales Invoice No.:
            <br />
            <input
              type="text"
              name="salesInvoiceNo"
              value={formData.salesInvoiceNo}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Sales Price:
            <br />
            <input
              type="number"
              name="salesPrice"
              value={formData.salesPrice}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Stamping:
            <br />
            <input
              type="text"
              name="stamping"
              value={formData.stamping}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Stamping Number:
            <br />
            <input
              type="text"
              name="stampingnum"
              value={formData.stampingnum}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          <label className="invoice-label">
            Application Number:
            <br />
            <input
              type="text"
              name="appnum"
              value={formData.appnum}
              onChange={handleChange}
              
              className="invoice-input"
            />
          </label>

          {additionalFields.map((field, index) => (
            <div key={index} className="invoice-dynamic-field">
              <label className="invoice-label">
                <input
                  type="text"
                  placeholder="Enter label name"
                  value={field.label}
                  onChange={(e) => handleLabelChange(e, index)}
                  className="invoice-input"
                />
              </label>
              <label className="invoice-label">
                <input
                  type="text"
                  placeholder="Enter value"
                  value={field.value}
                  onChange={(e) => handleChange(e, index)}
                  className="invoice-input"
                />
              </label>

              
            </div>
          ))}

          <button type="button" onClick={handleAddField} className="invoice-button">
            Add Field
          </button>

          <button type="submit" className="invoice-button">
            Generate Invoice
          </button>
        </form>
      )}
    </div>
  );
};

export default InvoiceForm;
