import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import InvoiceForm from './components/InvoiceForm';

// Component to provide branch name to children
const BranchProvider = ({ children }) => {
  const { branchName } = useParams(); // Extract branch name from URL

  return React.cloneElement(children, { branchName });
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/:branchName"
          element={
            <BranchProvider>
              <InvoiceForm />
            </BranchProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
